/**
 * File main.js.
 *
 * Main JS file
 */
registerRedirectionDependingOnLocation();

jQuery(document).ready(function () {
  const HASH = window.location.hash;
  const stopIconBlack = "/wp-content/themes/dac/img/icons/stop-black.svg";
  const playIconBlack = "/wp-content/themes/dac/img/icons/play-black.svg";
  const stopIconWhite = "/wp-content/themes/dac/img/icons/stop-white.svg";
  const playIconWhite = "/wp-content/themes/dac/img/icons/play-white.svg";
  const chevronLeft = "/wp-content/themes/dac/img/icons/chevron-left.svg";

  spacerHeight();
  bannerPaddings();
  ourTeamPaddings();
  heroInternalMaxHeight();
  poweredPaddings();
  phraseCarouselPaddings();

  jQuery(document).ready(function () {
    if (jQuery("#read-more-trigger").length) {
      if (jQuery(".large-description").length) {
        jQuery("#read-more-trigger")
          .closest(".large-description")
          .addClass("hasReadMore");
      }

      jQuery("#read-more-trigger").click(function (e) {
        e.preventDefault();

        var $trigger = jQuery(this);
        var $moreContent = $trigger.next(".more-content");
        var isDisabled = $trigger.data("show") === "disable";

        $moreContent.slideToggle();

        if (isDisabled) {
          $trigger.find("> div span:nth-of-type(1)").fadeOut(400, function () {
            $trigger.find("> div span:nth-of-type(2)").fadeIn(400);
            $trigger.data("show", "enable");
          });
        } else {
          $trigger.find("> div span:nth-of-type(2)").fadeOut(400, function () {
            $trigger.find("> div span:nth-of-type(1)").fadeIn(400);
            $trigger.data("show", "disable");
          });
        }
      });
    }
  });

  if (
    jQuery(
      "body.single-post header li.wpml-ls-current-language:not(.custom-dac)"
    ).length
  ) {
    jQuery(
      "body.single-post header li.wpml-ls-current-language:not(.custom-dac)"
    ).remove();
  }

  if (
    jQuery(
      "body.single-series header li.wpml-ls-current-language:not(.custom-dac)"
    ).length
  ) {
    jQuery(
      "body.single-series header li.wpml-ls-current-language:not(.custom-dac)"
    ).remove();
  }

  if (jQuery("#services_internal_menu").length) {
    jQuery("#services_internal_menu .menu h2").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();

      var target = jQuery(jQuery(this).data("target"));
      if (target.length) {
        jQuery("html, body").animate(
          {
            scrollTop: target.offset().top,
          },
          500
        );
      }

      // Move focus to the target element
      target.attr("tabindex", "-1"); // Adding tabindex for focus
      target.focus();
    });

    jQuery("#services_internal_menu .menu h2").on("keydown", function (e) {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        jQuery(this).click();
      }
    });
  }

  if (jQuery("#services-carousel").length) {
    jQuery(".carousel-wrapper").each(function () {
      initCarousel(this);
    });
  }

  /* start text and vimeo video block */

  if (jQuery(".text-vimeo-video").length) {
    if (jQuery(".text-vimeo-video #videoVimeo").length) {
      if (jQuery.fn.vimeo_player) {
        jQuery(".text-vimeo-video #videoVimeo #vimeoPlayer")
          .vimeo_player()
          .on("VPStart VPFallback", function () {
            jQuery(".text-vimeo-video #videoVimeo #loader").fadeOut(1000);
          });
      }

      jQuery(".text-vimeo-video #videoVimeo .buttons .play").on(
        "click",
        function (e) {
          e.preventDefault();
          e.stopPropagation();

          jQuery(this).toggleClass("active");
        }
      );
    }

    if (jQuery(".text-vimeo-video #videoURL")) {
      const videoPlayer = jQuery("#urlPlayer");
      const toggleButton = jQuery('[data-role="video-url-toggle-button"]');
      const buttonIcon = jQuery('[data-role="video-url-toggle-button"] img');
      videoPlayer.trigger("play");
      videoPlayer.data("status", "playing");

      const playVideo = () => {
        videoPlayer.trigger("play");
        videoPlayer.data("status", "playing");
        buttonIcon.attr("src", stopIconWhite);
      };

      const pauseVideo = () => {
        videoPlayer.trigger("pause");
        videoPlayer.data("status", "paused");
        buttonIcon.attr("src", playIconWhite);
      };

      toggleButton.click(function () {
        const isVideoCurrentlyPaused = videoPlayer.data("status") !== "playing";
        if (isVideoCurrentlyPaused) {
          playVideo();
        } else {
          pauseVideo();
        }
      });

      videoPlayer.on("ended", function () {
        this.load();
        this.play();
      });
    }
  }

  /* end text and vimeo video block */

  /* start Pie chart carousel block */

  if (jQuery("#pie-chart-carousel").length) {
    if (jQuery("#pie-chart-carousel .piechart").length) {
      jQuery("#pie-chart-carousel .piechart").gaugeMeter();

      jQuery("#pie-chart-carousel .carousel-wrapper").owlCarousel({
        loop: false,
        rewind: true,
        margin: 30,
        nav: true,
        dots: false,
        mouseDrag: true,
        touchDrag: true,
        items: 5,
        center: false,
        stagePadding: 0,
        autoHeight: true,
        onChanged: function (event) {
          jQuery("#pie-chart-carousel .piechart").gaugeMeter();
        },
        responsive: {
          0: {
            items: 1,
            dots: true,
            margin: 0,
          },

          600: {
            items: 2,
          },

          768: {
            items: 3,
          },

          992: {
            items: 4,
          },

          1200: {
            items: 5,
          },
        },

        onInitialized: updateNavButtons,
        onResized: updateNavButtons,
      });
    }
  }

  /* end Pie chart carousel block */

  /* start hero block */

  if (jQuery("#hero").length) {
    if (jQuery("#hero video").length) {
      //jQuery('#hero').vidbacking();

      var video = jQuery("#hero video")[0];
      var vtag = jQuery("#hero video");

      var isPlaying = true;

      vtag.on("play", function () {
        jQuery("#hero #loader").fadeOut(1000);
      });

      jQuery("#hero .buttons .play").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        elem = jQuery(this);

        jQuery("#hero")
          .find("[class^='video-container']")
          .each(function () {
            if (jQuery(this).is(":visible")) {
              thisVideo = jQuery(this).find("video")[0];

              if (isPlaying) {
                thisVideo.pause();
                elem.addClass("active");
              } else {
                thisVideo.play();
                elem.removeClass("active");
              }
              isPlaying = !isPlaying;
            }
          });
      });

      jQuery("#hero .buttons .volume").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        elem = jQuery(this);

        jQuery("#hero")
          .find("[class^='video-container']")
          .each(function () {
            if (jQuery(this).is(":visible")) {
              thisVideo = jQuery(this).find("video");

              elem.toggleClass("active");
              var isMuted = thisVideo.prop("muted");
              thisVideo.prop("muted", !isMuted);
            }
          });
      });
    }

    if (jQuery("#underline").length) {
      var words = jQuery(".sliding-word");
      var underline = jQuery("#underline");
      var forward = jQuery("h1 > div:nth-of-type(2)");
      var currentWordIndex = 0;

      function updateUnderline() {
        var currentWord = words.eq(currentWordIndex);
        var wordWidth = currentWord.outerWidth();
        var wordLeft = currentWord.position().left;

        forward.css({
          //left: wordLeft + wordWidth + 'px'
        });

        underline.prev().css({
          width: wordWidth + "px",
        });

        underline.css({
          width: wordWidth + "px",
        });

        currentWordIndex = (currentWordIndex + 1) % words.length;
      }

      // Update underline initially
      updateUnderline();

      // Set interval to update underline based on animation duration divided by the number of words
      var intervalDuration = 20000 / words.length; // 20s / number of words
      setInterval(updateUnderline, intervalDuration);
    }
  }

  /* end hero block */

  /* start hero internal block */

  if (jQuery("#hero-internal").length) {
    if (jQuery("#hero-internal video").length) {
      jQuery("#hero-internal").vidbacking();

      var video = jQuery("#hero-internal video")[0];
      var controlButton = jQuery("#hero-internal #play-pause-button img");
      var isPlaying = true;

      jQuery("#hero-internal #play-pause-button").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (isPlaying) {
          video.pause();
          controlButton.attr("src", playIconWhite);
        } else {
          video.play();
          controlButton.attr("src", stopIconWhite);
        }
        isPlaying = !isPlaying;
      });
    }
  }

  //#region [word & brand carousel]

  if (jQuery("#brands-carousel")) {
    const $carousel = jQuery("#brands-carousel .carousel-wrapper");
    const $controlButton = jQuery("#brands-carousel .control-button");
    const logoWidth = jQuery(
      "#brands-carousel .brand-carousel-item"
    ).outerWidth(true);
    const totalLogosWidth = logoWidth * $carousel.children().length;
    const speed = window.brandcarouselspeed || 90000;
    const cloneCount = 2;
    const direction = "right";
    let isPlaying = true;
    let animationTimer;
    let startTime;
    let remainingTime = speed;

    for (let i = 0; i < cloneCount; i++) {
      $carousel.append($carousel.children().clone());
    }

    function animateBrandsCarousel(duration) {
      let animationProperties = {};
      if (direction === "left") {
        animationProperties.left = -totalLogosWidth * cloneCount;
      } else if (direction === "right") {
        animationProperties.left = 0;
      }

      startTime = Date.now();

      $carousel.animate(animationProperties, {
        duration: duration,
        easing: "linear",
        step: function (now, fx) {
          if (!isPlaying) {
            $carousel.stop(true, false);
          }
        },
        complete: function () {
          if (direction === "left") {
            $carousel.css("left", "0");
          } else if (direction === "right") {
            $carousel.css("left", -totalLogosWidth * cloneCount);
          }
          if (isPlaying) {
            remainingTime = speed;
            animationTimer = setTimeout(() => animateBrandsCarousel(speed), 0);
          }
        },
      });
    }

    function pauseBrandsCarousel() {
      isPlaying = false;
      clearTimeout(animationTimer);
      const elapsedTime = Date.now() - startTime;
      remainingTime = Math.max(0, remainingTime - elapsedTime);
      $carousel.stop(true, false);
    }

    function resumeBrandsCarousel() {
      isPlaying = true;
      const currentPosition = parseFloat($carousel.css("left"));
      let targetPosition;

      if (direction === "left") {
        targetPosition = -totalLogosWidth * cloneCount;
      } else if (direction === "right") {
        targetPosition = 0;
      }

      const remainingDistance = Math.abs(targetPosition - currentPosition);
      const totalDistance = totalLogosWidth * cloneCount;
      const adjustedDuration = (remainingDistance / totalDistance) * speed;

      $carousel.animate(
        { left: targetPosition },
        {
          duration: adjustedDuration,
          easing: "linear",
          complete: function () {
            if (direction === "left") {
              $carousel.css("left", "0");
            } else if (direction === "right") {
              $carousel.css("left", -totalLogosWidth * cloneCount);
            }
            animateBrandsCarousel(speed);
          },
        }
      );
    }

    function toggleBrandsCarousel() {
      if (isPlaying) {
        pauseBrandsCarousel();
        $controlButton.find("img").attr("src", playIconBlack);
      } else {
        resumeBrandsCarousel();
        $controlButton.find("img").attr("src", stopIconBlack);
      }
    }

    $controlButton.on("click", toggleBrandsCarousel);

    $carousel.css("width", totalLogosWidth * (cloneCount + 1));
    if (direction === "right") {
      $carousel.css("left", -totalLogosWidth * cloneCount);
    }
    animateBrandsCarousel(speed);
  }

  if (jQuery("#words-carousel")) {
    const $carousel = jQuery("#words-carousel .carousel-wrapper");
    const $controlButton = jQuery(".control-button");
    const logoWidth = jQuery("#words-carousel .word-carousel-item").outerWidth(
      true
    );
    const totalLogosWidth = logoWidth * $carousel.children().length;
    const speed = 30000;
    const cloneCount = 2;
    const direction = "right";
    let isPlaying = true;
    let animationTimer;
    let startTime;
    let remainingTime = speed;

    for (let i = 0; i < cloneCount; i++) {
      $carousel.append($carousel.children().clone());
    }

    function animateWordsCarousel(duration) {
      let animationProperties = {};
      if (direction === "left") {
        animationProperties.left = -totalLogosWidth * cloneCount;
      } else if (direction === "right") {
        animationProperties.left = 0;
      }

      startTime = Date.now();

      $carousel.animate(animationProperties, {
        duration: duration,
        easing: "linear",
        step: function (now, fx) {
          if (!isPlaying) {
            $carousel.stop(true, false);
          }
        },
        complete: function () {
          if (direction === "left") {
            $carousel.css("left", "0");
          } else if (direction === "right") {
            $carousel.css("left", -totalLogosWidth * cloneCount);
          }
          if (isPlaying) {
            remainingTime = speed;
            animationTimer = setTimeout(() => animateWordsCarousel(speed), 0);
          }
        },
      });
    }

    function pauseWordsCarousel() {
      isPlaying = false;
      clearTimeout(animationTimer);
      const elapsedTime = Date.now() - startTime;
      remainingTime = Math.max(0, remainingTime - elapsedTime);
      $carousel.stop(true, false);
    }

    function resumeWordsCarousel() {
      isPlaying = true;
      const currentPosition = parseFloat($carousel.css("left"));
      let targetPosition;

      if (direction === "left") {
        targetPosition = -totalLogosWidth * cloneCount;
      } else if (direction === "right") {
        targetPosition = 0;
      }

      const remainingDistance = Math.abs(targetPosition - currentPosition);
      const totalDistance = totalLogosWidth * cloneCount;
      const adjustedDuration = (remainingDistance / totalDistance) * speed;

      $carousel.animate(
        { left: targetPosition },
        {
          duration: adjustedDuration,
          easing: "linear",
          complete: function () {
            if (direction === "left") {
              $carousel.css("left", "0");
            } else if (direction === "right") {
              $carousel.css("left", -totalLogosWidth * cloneCount);
            }
            animateWordsCarousel(speed);
          },
        }
      );
    }

    function toggleCarousel() {
      if (isPlaying) {
        pauseWordsCarousel();
        $controlButton
          .find("img")
          .attr("src", "/wp-content/themes/dac/img/icons/play-white.svg");
      } else {
        resumeWordsCarousel();
        $controlButton
          .find("img")
          .attr("src", "/wp-content/themes/dac/img/icons/stop-white.svg");
      }
    }

    $controlButton.on("click", toggleCarousel);

    $carousel.css("width", totalLogosWidth * (cloneCount + 1));
    if (direction === "right") {
      $carousel.css("left", -totalLogosWidth * cloneCount);
    }
    animateWordsCarousel(speed);
  }

  //#endregion

  /* end hero internal block */

  /* start phrase_carousel block */

  if (jQuery(".phrase-carousel").length) {
    if (jQuery(".phrase-carousel").data("carousel") == "enable") {
      var isPlaying = true;
      var $playPauseButton = jQuery(".phrase-carousel #play-pause-button");
      var $animatedElements = jQuery(
        ".phrase-carousel,.phrase-carousel .scroller .inner"
      );

      $playPauseButton.on("click", function () {
        if (isPlaying) {
          $animatedElements.addClass("paused");
          $playPauseButton.find("img").attr("src", playIconBlack);
        } else {
          $animatedElements.removeClass("paused");
          $playPauseButton.find("img").attr("src", stopIconBlack);
        }
        isPlaying = !isPlaying;
      });
    }
  }

  /* end phrase_carousel block */

  /* start gif animated */

  if (jQuery(".image-gif-animated").length) {
    jQuery(".image-gif-animated").each(function () {
      if (typeof jQuery.fn.JKGifPlayer === "function") {
        jQuery(this).find(".div_with_data_gif").JKGifPlayer({
          autoplay: true,
          data: "gif",
          autopause: false,
        });
      }

      const $controlButton = jQuery(this).find("button");
      const $gifContainer = jQuery(this).find(".div_with_data_gif");
      let isPlaying = true;
      //let isTransitioning = false;  // Flag to prevent rapid clicks

      $controlButton.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        //if (isTransitioning) return;  // Prevent further clicks until the delay is over

        //isTransitioning = true;
        //setTimeout(function () {
        //isTransitioning = false;
        //}, 500);  // Delay of 500 milliseconds

        $gifContainer.PlayStop_Gif();

        if (isPlaying) {
          isPlaying = false;
          $controlButton.html('<img src="' + playIconWhite + '" alt="play" />');
        } else {
          isPlaying = true;
          $controlButton.html('<img src="' + stopIconWhite + '" alt="stop" />');
        }
      });
    });
  }

  /* end gif animated */

  /* start teams block videos */

  if (jQuery(".video-animated").length) {
    jQuery(".video-animated").each(function () {
      var elem = jQuery(this);
      var video = elem.find("video")[0];
      var $controlButton = elem.find(".control-button");

      var isPlaying = true;
      elem.data("isPlaying", isPlaying);

      const updateIsPlaying = (value) => {
        isPlaying = value;
        elem.data("isPlaying", isPlaying);
        updateButtonState();
      };

      const updateButtonState = () => {
        if (isPlaying) {
          $controlButton.html('<img src="' + stopIconWhite + '" alt="stop" />');
        } else {
          $controlButton.html('<img src="' + playIconWhite + '" alt="play" />');
        }
      };

      const playVideo = () => {
        video.play().catch((error) => {
          if (error.name === "AbortError") {
            updateIsPlaying(false);
          }
        });
      };

      $controlButton.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (isPlaying) {
          updateIsPlaying(false);
          video.pause();
        } else {
          updateIsPlaying(true);
          playVideo();
        }
      });

      var observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Only play the video if it was playing before leaving the viewport
              if (elem.data("isPlaying")) {
                playVideo();
              }
            } else {
              video.pause();
            }
            updateButtonState();
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(elem[0]);
      updateButtonState();
    });
  }

  /* end teams block videos */

  /* start text and video 2 block */

  if (jQuery(".text-video-second").length) {
    if (jQuery(".text-video-second video").length) {
      var video = jQuery(".text-video-second video")[0];
      var vtag = jQuery(".text-video-second video");

      jQuery(".video-internal").vidbacking();

      var isPlaying = true;

      vtag.on("play", function () {
        jQuery(".text-video-second #loader").fadeOut(1000);
      });

      var $controlButton = jQuery(
        ".text-video-second .video-internal .control-button"
      );

      var isPlaying = true;

      $controlButton.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (isPlaying) {
          isPlaying = false;
          video.pause();
          $controlButton.html('<img src="' + playIconWhite + '" alt="play" />');
        } else {
          isPlaying = true;
          video.play();
          $controlButton.html('<img src="' + stopIconWhite + '" alt="stop" />');
        }
      });
    }
  }

  /* end text and video 2 block */

  oneTime = true;
  jQuery(window).scroll(function () {
    /* start counter for number block */

    if (jQuery("#stats").length) {
      var hT = jQuery("#stats").offset().top,
        hH = jQuery("#stats").outerHeight(),
        wH = jQuery(window).height(),
        wS = jQuery(this).scrollTop();

      if (wS > hT - hH - 300 && oneTime) {
        oneTime = false;
        jQuery("#stats .item").each(function () {
          var elem = jQuery(this).find("h3");

          elem.prop("Counter", 0).animate(
            {
              Counter: elem.text(),
            },
            {
              duration: 3000,
              easing: "swing",
              step: function (now) {
                elem.text(
                  Math.ceil(now)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              },
              complete: function () {
                if (jQuery(this).hasClass("plus")) {
                  thisText = elem.text();
                  elem.text(thisText + "+");
                }
              },
            }
          );
        });
      }
    }

    /* end counter for number block */
  });

  // Make all carousel titles the same height
  // Find all services-carousel__items containers
  const carousels = document.querySelectorAll(".services-carousel__items");

  // Iterate through each carousel container
  carousels.forEach(function (carousel) {
    // Find all services-carousel__item-title elements within this carousel
    const titles = carousel.querySelectorAll(".services-carousel__item-title");
    let maxHeight = 0;

    // Find the maximum height among all titles in this carousel
    titles.forEach(function (title) {
      const height = title.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    // Apply the maximum height to all titles in this carousel
    titles.forEach(function (title) {
      title.style.height = maxHeight + "px";
    });
  });

  if (jQuery("[data-share]")) {
    jQuery("[data-share]").each(function (index) {
      const url = window.location.href;
      const title = document.title;
      const elementSelected = this;
      const shareBy = elementSelected.dataset.share;
      let clickFunction = function () {};

      switch (shareBy) {
        case "email":
          clickFunction = function () {
            const body = encodeURIComponent(
              `I wanted to share this interesting content with you:\n\n${url}`
            );
            window.location.href = `mailto:?subject=${title}&body=${body}`;
          };
          break;
        case "facebook":
          clickFunction = function () {
            const facebookShareUrl = new URL('https://www.facebook.com/sharer/sharer.php');
            facebookShareUrl.searchParams.set('u', url);

            window.open(
              facebookShareUrl.toString(),
              'facebook-share-dialog',
              'width=626,height=436,resizable=yes,scrollbars=yes'
            );
          }
          break;
        case "linkedin":
          clickFunction = function () {
            const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;

            window.open(
              shareUrl,
              "linkedin-share-dialog",
              "width=626,height=436,resizable=yes,scrollbars=yes"
            );
          };
          break;
      }

      elementSelected.addEventListener("click", clickFunction);
    });
  }

  /**
   * lazy loads a video element with mp4 file
   * the element must have the data attributes
   * - data-lazy-load
   * - data-src
   */
  if (jQuery("[data-lazy-load]")) {
    function addSourceToLazilyLoadedVideoElement(elementSelected) {
      const source = document.createElement("source");
      const videoSource = elementSelected.dataset.src;
      source.type = "video/mp4";
      source.src = videoSource;
      source.classList.add("video-source");
      elementSelected.appendChild(source);
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            addSourceToLazilyLoadedVideoElement(entry.target);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    jQuery("[data-lazy-load]").each(function (index) {
      observer.observe(this);
    });
  }

  /**
   * Phrases carousel
   * TODO: move this to a separate JS (ML)
   */
  // Check if the .phrase-carousel element exists
  if (jQuery(".phrase-carousel").length > 0) {
    const $scroller = jQuery(".scroller .inner");
    const $items = $scroller.find(".phrase");
    const $carousel = jQuery(".phrase-carousel"); // To change the background color
    let currentIndex = 0;
    let intervalId;

    function showNextItem() {
      // Check if the carousel has the class 'paused'
      if ($carousel.hasClass("paused")) {
        return; // Stop the animation if paused
      }

      // Hide the current div
      const $currentItem = $items.eq(currentIndex);
      $currentItem.removeClass("active").addClass("fadeout");

      // Calculate the next index
      currentIndex = (currentIndex + 1) % $items.length;
      const $nextItem = $items.eq(currentIndex);

      // Change the background-color of the .phrase-carousel according to the next div
      const newColor = $nextItem.data("bgcolor");
      $carousel.css("background-color", newColor);

      // Show the next div
      setTimeout(function () {
        $currentItem.removeClass("fadeout"); // Reset the fadeout class
        $nextItem.addClass("active");
      }, 500); // Time for the fadeout animation
    }

    // Initialize the first item
    $items.eq(currentIndex).addClass("active");
    $carousel.css("background-color", $items.eq(currentIndex).data("bgcolor"));

    // Function to start the cycle with setInterval
    function startCarousel() {
      intervalId = setInterval(showNextItem, 4000); // Show the next div every 3 seconds
    }

    // Start the carousel cycle
    startCarousel();
  }

  jQuery(window).on("resize", function () {
    spacerHeight();
    bannerPaddings();
    ourTeamPaddings();
    heroInternalMaxHeight();
    poweredPaddings();
    phraseCarouselPaddings();
  });

  overrideWPMLActiveMenuItemHref();
  avoidScrollToTOpOnPageWhenMenuLinksAreClicked();
  smoothNavigationForInternalServicesMenuBlock();
});

function smoothNavigationForInternalServicesMenuBlock() {
  const links = document.querySelectorAll(".smooth-scroll");
  links.forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("data-scroll-to").substring(1);
      const scrollPosition =
        this.getAttribute("data-scroll-position") || "center"; // Default to center
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const windowHeight = window.innerHeight;
        const targetRect = targetElement.getBoundingClientRect();
        const targetHeight = targetRect.height;
        const targetTop = targetRect.top + window.pageYOffset;
        let scrollTarget;

        switch (scrollPosition) {
          case "top":
            scrollTarget = targetTop - 100;
            break;
          case "bottom":
            scrollTarget = targetTop + targetHeight - windowHeight;
            break;
          case "center":
          default:
            scrollTarget = targetTop - windowHeight / 2 + targetHeight / 2;
            break;
        }

        window.scrollTo({
          top: scrollTarget,
          behavior: "smooth",
        });
      }
    });
  });
}

function avoidScrollToTOpOnPageWhenMenuLinksAreClicked() {
  jQuery(".main-navigation li.menu-item-has-children > a").each(function () {
    const item = jQuery(this);
    item.click(function (e) {
      e.preventDefault();
    });
  });
}

function overrideWPMLActiveMenuItemHref() {
  jQuery("ul#top-nav > li.menu-item > a[title]").attr("href", "#");
}

function spacerHeight() {
  if (jQuery(".custom-spacer").length) {
    jQuery(".custom-spacer").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const mobileHeight = item.data("mobile");
      const desktopHeight = item.data("desktop");
      item.css("height", width < 992 ? mobileHeight : desktopHeight);
    });
  }
}

function ourTeamPaddings() {
  if (jQuery(".ourteam").length) {
    jQuery(".ourteam").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const ptd = item.data("ptd");
      const pbd = item.data("pbd");
      const ptm = item.data("ptm");
      const pbm = item.data("pbm");
      if (width < 992) {
        item.css("padding-top", ptm !== "" ? ptm : ptd);
        item.css("padding-bottom", pbm !== "" ? pbm : pbd);
      } else {
        item.css("padding-top", ptd);
        item.css("padding-bottom", pbd);
      }
    });
  }
}

function bannerPaddings() {
  if (jQuery(".banner").length) {
    jQuery(".banner").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const ptd = item.data("ptd");
      const pbd = item.data("pbd");
      const ptm = item.data("ptm");
      const pbm = item.data("pbm");
      if (width < 992) {
        item.css("padding-top", ptm !== "" ? ptm : ptd);
        item.css("padding-bottom", pbm !== "" ? pbm : pbd);
      } else {
        item.css("padding-top", ptd);
        item.css("padding-bottom", pbd);
      }
    });
  }
}

function phraseCarouselPaddings() {
  if (jQuery(".phrase-carousel").length) {
    jQuery(".phrase-carousel").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const ptd = item.data("ptd");
      const pbd = item.data("pbd");
      const ptm = item.data("ptm");
      const pbm = item.data("pbm");
      const bgcolor = item.data("background");

      item.css("background", bgcolor !== "" ? bgcolor : "");

      if (width < 992) {
        item.css("padding-top", ptm !== "" ? ptm : ptd);
        item.css("padding-bottom", pbm !== "" ? pbm : pbd);
      } else {
        item.css("padding-top", ptd);
        item.css("padding-bottom", pbd);
      }
    });
  }
}

function heroInternalMaxHeight() {
  if (jQuery("#hero-internal").length) {
    jQuery("#hero-internal").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const mobileHeight = item.data("mobileh");
      const desktopHeight = item.data("desktoph");
      item.css("min-height", width < 992 ? mobileHeight : desktopHeight);
    });
  }
}

function poweredPaddings() {
  if (jQuery(".powered").length) {
    jQuery(".powered").each(function () {
      const item = jQuery(this);
      const width = jQuery(window).width();
      const ptd = item.data("ptd");
      const pbd = item.data("pbd");
      const ptm = item.data("ptm");
      const pbm = item.data("pbm");
      if (width < 992) {
        item.css("padding-top", ptm !== "" ? ptm : ptd);
        item.css("padding-bottom", pbm !== "" ? pbm : pbd);
      } else {
        item.css("padding-top", ptd);
        item.css("padding-bottom", pbd);
      }
    });
  }
}

/** Cards Grid JS
 * TODO: Move this to a separate JS file (ML)
 */
document.addEventListener("DOMContentLoaded", function () {
  const cardsGridBlocks = document.querySelectorAll(".cards-grid-block");

  cardsGridBlocks.forEach(function (cardsGridBlock) {
    const loadMoreButton = cardsGridBlock.querySelector(".load-more");
    const cards = cardsGridBlock.querySelectorAll(".card-item");
    let currentCount = 9;

    if (loadMoreButton) {
      loadMoreButton.addEventListener("click", function () {
        // Show the next 9 cards in this specific cards grid block
        for (let i = currentCount; i < currentCount + 9; i++) {
          if (cards[i]) {
            cards[i].style.display = "block";
          }
        }
        // Update the count of displayed cards
        currentCount += 9;

        // If all cards are shown, hide the load more button
        if (currentCount >= cards.length) {
          loadMoreButton.style.display = "none";
        }
      });
    }
  });
});

function initCarousel(carouselElement) {
  jQuery(carouselElement).owlCarousel({
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    mouseDrag: true,
    touchDrag: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: true,
      },

      768: {
        items: 2,
        nav: true,
      },

      992: {
        items: 2,
        nav: true,
      },

      1200: {
        nav: true,
        mouseDrag: false,
        touchDrag: false,
        autoplay: false,
      },
    },

    onInitialized: updateNavButtons,
    onResized: updateNavButtons,
  });
}

function updateNavButtons(event) {
  let element = event.target;
  const $element = jQuery(element);

  $element
    .find(".owl-prev")
    .empty()
    .html(
      `
                <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1192 21.6417C13.6269 21.1644 13.6269 20.3906 13.1192 19.9133L3.63856 10.9998L13.1192 2.0864C13.6269 1.60908 13.6269 0.835264 13.1192 0.357977C12.6115 -0.11931 11.7885 -0.119343 11.2808 0.357977L0.880781 10.1358C0.637032 10.3649 0.500001 10.6758 0.500001 11C0.500001 11.3242 0.637042 11.635 0.880781 11.8642L11.2808 21.642C11.7885 22.1193 12.6116 22.1193 13.1192 21.642V21.6417Z"/>
                </svg>
            `.trim()
    );

  $element
    .find(".owl-next")
    .empty()
    .html(
      `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.35148 22.6417C5.88284 22.1644 5.88284 21.3906 6.35148 20.9133L15.1029 11.9998L6.35148 3.0864C5.88284 2.60908 5.88284 1.83526 6.35148 1.35798C6.82012 0.88069 7.57987 0.880657 8.04848 1.35798L17.6485 11.1358C17.8735 11.3649 18 11.6758 18 12C18 12.3242 17.8735 12.635 17.6485 12.8642L8.04848 22.642C7.57984 23.1193 6.82009 23.1193 6.35148 22.642V22.6417Z"/>
                </svg>
            `.trim()
    );
}

function showBanner(message, onYes, onNo, yesText = null) {
  const bannerBackdrop = jQuery("<div>")
    .addClass("dynamic-banner-backdrop")
    .appendTo("body");

  const banner = jQuery("<div>")
    .addClass("dynamic-banner")
    .appendTo(bannerBackdrop);

  const closeButton = jQuery("<button>")
    .addClass("d-flex")
    .addClass("justify-content-center")
    .addClass("align-items-center")
    .addClass("mb-3")
    .addClass("close-language-banner-button").html(`
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5983 18.8121L13.7992 12.0019L20.5983 5.19175C21.1001 4.68914 21.1001 3.87819 20.5983 3.37696C20.0965 2.87435 19.2869 2.87435 18.7865 3.37696L11.9873 10.1871L5.1882 3.37696C4.68641 2.87435 3.87677 2.87435 3.37635 3.37696C2.87455 3.87956 2.87455 4.69051 3.37635 5.19175L10.1755 12.0019L3.37635 18.8121C2.87455 19.3147 2.87455 20.1257 3.37635 20.6269C3.63357 20.8845 3.95406 21 4.28861 21C4.62315 21 4.94362 20.8719 5.20087 20.6269L12 13.8167L18.7991 20.6269C19.0564 20.8845 19.3769 21 19.7114 21C20.0459 21 20.3664 20.8719 20.6237 20.6269C21.1254 20.1243 21.1254 19.3133 20.6237 18.8121H20.5983Z" fill="black"/>
            </svg>
        `);

  const buttonRow = jQuery("<div>")
    .addClass("d-flex")
    .addClass("justify-content-end")
    .addClass("align-it ems-center")
    .append(closeButton)
    .appendTo(banner);

  const content = jQuery("<div>")
    .addClass("dynamic-banner-content")
    .html(message)
    .appendTo(banner);

  const buttonContainer = jQuery("<div>")
    .addClass("dynamic-banner-buttons")
    .appendTo(banner);

  const noButton = jQuery("<button>")
    .addClass("dynamic-banner-button dynamic-no-button")
    .text("No")
    .appendTo(buttonContainer);

  const yesButton = jQuery("<button>")
    .addClass("dynamic-banner-button dynamic-yes-button")
    .text(yesText ?? "Yes")
    .appendTo(buttonContainer);

  // Handle button clicks
  yesButton.click(function () {
    banner.fadeOut(300, function () {
      banner.remove();
    });
    bannerBackdrop.fadeOut(300, function () {
      bannerBackdrop.remove();
    });

    localStorage.setItem("redirectChoice", "yes");
    if (onYes) onYes();
  });

  noButton.click(function () {
    banner.fadeOut(300, function () {
      banner.remove();
    });
    bannerBackdrop.fadeOut(300, function () {
      bannerBackdrop.remove();
    });
    localStorage.setItem("redirectChoice", "no");
    if (onNo) onNo();
  });

  closeButton.click(function () {
    bannerBackdrop.fadeOut(300, function () {
      bannerBackdrop.remove();
    });
    banner.fadeOut(300, function () {
      banner.remove();
    });
    localStorage.setItem("redirectChoice", "no");
  });

  // Show banner with fade effect
  banner.fadeIn(300);
  bannerBackdrop.fadeIn(300);

  // Return banner element in case further manipulation is needed
  return banner;
}

function registerRedirectionDependingOnLocation() {
  const TRANSLATED_MESSAGES = {
    US: "We noticed you are located in the United States. <br> Do you want to visit our English site?",
    GB: "We noticed you are located in the United Kingdom. <br> Do you want to visit our UK site",
    CA: "We noticed you are located in Canada.<br> Do you want to visit our Canadian site?",
    FR: "Nous avons remarqué que vous êtes en France. <br> Souhaitez-vous accéder à notre site français ?",
    CAQC: "Nous avons remarqué que vous êtes au Canada. <br> Souhaitez-vous accéder à notre site canadien en français?",
    ES: "Hemos detectado que estás en España, <br> ¿quieres visitar nuestra web en español?",
    DE: "Wir haben festgestellt, dass Sie sich in Deutschland befinden. <br> Möchten Sie unsere deutsche Website besuchen?",
  };

  const YES_BUTTON_MESSAGES = {
    US: "Yes, visit our US English site",
    GB: "Yes, visit our UK site",
    CA: "Yes, visit our English Canadian site",
    FR: "Oui, visiter notre site français",
    CAQC: "Oui, visiter notre site canadien en français",
    DE: "Ja, ich möchte die deutsche Website besuchen",
    ES: "Si, deseo visitar la web española",
  };

  const COUNTRY_NAMES = ["US", "CA", "GB", "DE", "FR", "ES"];

  const CONFIG = {
    cacheDuration: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
    redirectMap: {
      CA: {
        QC: "/fr-ca/",
        default: "/en-ca/",
      },
      DE: "/de/",
      GB: "/en-gb/",
      FR: "/fr/",
      ES: "/es/",
      US: "/",
      default: "/",
    },
  };

  function getBodyMessage(country, province) {
    if (country === "CA") {
      return province === "QC"
        ? TRANSLATED_MESSAGES["CAQC"]
        : TRANSLATED_MESSAGES["CA"];
    }

    return TRANSLATED_MESSAGES[country];
  }

  function getYesButtonMessage(country, province) {
    if (country === "CA") {
      return province === "QC"
        ? YES_BUTTON_MESSAGES["CAQC"]
        : YES_BUTTON_MESSAGES["CA"];
    }

    return YES_BUTTON_MESSAGES[country];
  }

  function hasValidRedirectFlag() {
    const redirectTime = localStorage.getItem("redirectTimestamp");
    const redirectChoice = localStorage.getItem("redirectChoice");
    return (
      redirectTime &&
      redirectChoice &&
      Date.now() - redirectTime < CONFIG.cacheDuration
    );
  }

  if (hasValidRedirectFlag()) {
    return;
  }

  function getRedirectUrl(country, province) {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;

    let localePath;
    if (country === "CA") {
      localePath =
        province === "QC"
          ? CONFIG.redirectMap.CA.QC
          : CONFIG.redirectMap.CA.default;
    } else {
      localePath = CONFIG.redirectMap[country] || CONFIG.redirectMap.default;
    }

    const pathSegments = currentPath.split("/").filter(Boolean);
    const knownLocales = ["fr-ca", "en-ca", "de", "en-gb", "fr", "es"];

    let contentPath = "";
    if (pathSegments.length > 0) {
      const firstSegment = pathSegments[0];
      if (knownLocales.some((locale) => firstSegment.startsWith(locale))) {
        contentPath = "/" + pathSegments.slice(1).join("/");
      } else {
        contentPath = "/" + pathSegments.join("/");
      }
    }

    if (contentPath.length > 1 && !contentPath.endsWith("/")) {
      contentPath += "/";
    }

    const fullPath = (localePath + contentPath).replace(/\/+/g, "/");

    return `${baseUrl}${fullPath}`;
  }

  function setRedirectFlag() {
    localStorage.setItem("redirectTimestamp", Date.now().toString());
  }

  function redirectBasedOnLocation(data) {
    const redirectUrl = getRedirectUrl(
      data.country,
      data.region || data.province
    );

    if (window.location.href !== redirectUrl) {
      setRedirectFlag();
      window.location.href = redirectUrl;
    } else {
      setRedirectFlag();
    }
  }

  function shouldShowBanner() {
    const urlIsWPAdmin = window.location.href.includes("wp-admin");
    if (urlIsWPAdmin) {
      return false;
    }

    const redirectChoice = localStorage.getItem("redirectChoice");
    const redirectTime = localStorage.getItem("redirectTimestamp");
    return (
      !redirectChoice || // No choice has been made
      (redirectTime && Date.now() - redirectTime >= CONFIG.cacheDuration)
    ); // Cache expired
  }

  function handleGeolocation() {
    const wmplLangcodeToCountry = {
      en: "US",
      "en-ca": "CA",
      "en-gb": "GB",
      fr: "FR",
      "fr-ca": "CA",
      de: "DE",
      es: "ES",
    };
    const currentLanguageElement = document.querySelector(
      "[data-current-language]"
    );
    const currentLanguageCode =
      currentLanguageElement?.dataset?.currentLanguage;
    const currentCountryLocation =
      wmplLangcodeToCountry[currentLanguageCode] || "";
    const currentLocation = window.location.href;
    const cachedData = JSON.parse(localStorage.getItem("geoData"));
    const cacheTime = localStorage.getItem("geoDataTimestamp");

    if (!!cachedData && !COUNTRY_NAMES.includes(cachedData?.country)) {
      return;
    }

    // If we have cached data and it's still valid
    if (
      cachedData &&
      cacheTime &&
      Date.now() - cacheTime < CONFIG.cacheDuration
    ) {
      if (shouldShowBanner()) {
        const redirectUrl = getRedirectUrl(
          cachedData.country,
          cachedData.region || cachedData.province
        );

        if (redirectUrl !== currentLocation) {
          const formatedBodyMessage = getBodyMessage(
            cachedData.country,
            cachedData.region || cachedData.province
          );
          const formatedButtonMessage = getYesButtonMessage(
            cachedData.country,
            cachedData.region || cachedData.province
          );

          showBanner(
            formatedBodyMessage,
            function () {
              redirectBasedOnLocation(cachedData);
            },
            function () {},
            formatedButtonMessage
          );
        }
      }
      return;
    }

    jQuery
      .ajax({
        url: "https://ipapi.co/json/",
        method: "GET",
        dataType: "json",
      })
      .done(function (data) {
        localStorage.setItem("geoData", JSON.stringify(data));
        localStorage.setItem("geoDataTimestamp", Date.now().toString());

        if (data && !COUNTRY_NAMES.includes(data.country)) {
          return;
        }

        if (!shouldShowBanner()) {
          return;
        }

        const redirectUrl = getRedirectUrl(
          data.country,
          data.region || data.province
        );
        if (redirectUrl === currentLocation) {
          return;
        }

        if (
          data &&
          currentCountryLocation == data.country_code &&
          data.country_code != "CA"
        ) {
          return;
        }

        if (data.country === "CA") {
          const isQuebec = data.region === "QC" || data.province === "QC";

          if (
            (isQuebec && currentLanguageCode === "fr-ca") ||
            (!isQuebec && currentLanguageCode === "en-ca")
          ) {
            return;
          }
        }

        const formatedBodyMessage = getBodyMessage(
          data.country,
          data.region || data.province
        );
        const formatedButtonMessage = getYesButtonMessage(
          data.country,
          data.region || data.province
        );

        showBanner(
          formatedBodyMessage,
          function () {
            redirectBasedOnLocation(data);
          },
          function () {},
          formatedButtonMessage
        );
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        console.error("Geolocation API error:", errorThrown);
        setRedirectFlag(); // Set flag even on error to prevent repeated tries
      });
  }

  handleGeolocation();
}

//property override for prototype of media elements make code easier to handle
Object.defineProperty(HTMLMediaElement.prototype, "playing", {
  get: function () {
    return !!(
      this.currentTime > 0 &&
      !this.paused &&
      !this.ended &&
      this.readyState > 2
    );
  },
});

// removed 100vh from shadow dom of usercentrics component, since it cant be done from css
document.addEventListener("DOMContentLoaded", () => {
    let interval = setInterval(() => 
    {
        const overlay = document.getElementById("usercentrics-cmp-ui");
        if (!overlay) {
          return;
        }
      
        const shadowRoot = overlay.shadowRoot;
        if (!shadowRoot) {
          return;
        }
        let cmp = shadowRoot.querySelector(".cmp-wrapper .cmp");
        if (!!cmp) {
          cmp.style.height = "unset";
          clearInterval(interval);
        }
    }, 400)
} );